import SelectLanguage from "Website/Language/Components/SelectLanguage";

const HeaderRight = (props) => {
    return (
        <ul className={`header_nav ${props.deviceType || ""}`}>
            <li>
                <SelectLanguage handleSetLanguage={props.handleSetLanguage} selectedLang={props.selectedLang} />
            </li>
            <li><a href="/launchingSoon">Android</a></li>
            <li><a href="/launchingSoon">iOS</a></li>
        </ul>
    )
}

export default HeaderRight;
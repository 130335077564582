import { HeartIcon, ThemeIcon } from "Website/assets/images";
import emoji1 from 'Website/assets/images/emoji1.png';
import emoji2 from 'Website/assets/images/emoji2.png';

const Launching = () => {
    return (
        <>
            <section className="content_banner_section launching_section">
                <i className="smile_icon"><img src={ emoji1 } title='' alt=''/></i>
                <i className="smileemoj_icon"><img src={ emoji2 } title='' alt=''/></i>
                <h1>
                    <i className="heart_icon"><HeartIcon/></i>
                    Launching Soon
                    <i className="theme_icon"><ThemeIcon/></i>
                </h1>
            </section>
        </>
    )
}
export default Launching;
import { APP_NAME } from "features/App/constants";
import { capitalizeFirstLetter } from "features/Helpers/StringFormat";
import { NavLink } from "react-router-dom";
import logo from "Website/assets/images/enkrip_logo.png";

const Logo = () => {
    return (
        <NavLink to={"/"} className="logo">
            <img src={logo} alt={capitalizeFirstLetter(APP_NAME)} title={capitalizeFirstLetter(APP_NAME)} />
        </NavLink>
    )
}

export default Logo;
import ReactOutsideClickHandler from "react-outside-click-handler";

const OutsideClickHandler = (props) => {
    const handleOutsideClick = (event) => {
        if(props.outsideClickBlockId){
            const elementName = props.elementName || 'i';
            const blockClickOnElement = event.target.closest(elementName);
            // Block outside logic when click on emoji icon to prevent collapse of onOutsideClick and emoji
            // icon click
            if(blockClickOnElement && blockClickOnElement?.getAttribute('data-id') === props.outsideClickBlockId){
                return;
            }
        }

        props.onOutsideClick && props.onOutsideClick(false);
    }

    return <ReactOutsideClickHandler onOutsideClick={handleOutsideClick} >
        {props.children}
    </ReactOutsideClickHandler>
}

export default OutsideClickHandler;
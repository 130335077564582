import i18n from "features/Languages/i18n";
import { DEFAULT_LANGUAGE, LANGUAGES } from "./constants";

export const getSelectedLang = (lang) => {
    const selectedLang = lang || i18n.language || DEFAULT_LANGUAGE;
    if(selectedLang.includes(LANGUAGES.en)){
        return LANGUAGES.en;
    }
    else if(selectedLang.includes(LANGUAGES.id)){
        return LANGUAGES.id;
    }
    else {
        return DEFAULT_LANGUAGE;
    }
}
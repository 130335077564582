import { FacebookIcon, InstagramIcon, LinkedIcon, TwitterIcon } from "Website/assets/images";
import appstore from 'Website/assets/images/appstore.png';
import googleplay from 'Website/assets/images/googleplay.png';
import SelectLanguage from 'Website/Language/Components/SelectLanguage';
import Menu from 'Website/Menu/Components/Menu';
import Logo from "./Logo";


const Footer = (props) => {
    return (
        <footer>
            <Logo />
            <div className='footer_nav_block'>
                <Menu displayMenu={['AboutMenu', 'PrivacyMenu', 'TermsMenu', 'ContactUsMenu', 'EnkripWebMenu']} />
                <div>
                    <p>2024 &copy; PT. Cahaya Sinergi Sentosa</p>
                    <SelectLanguage handleSetLanguage={props.handleSetLanguage} selectedLang={props.selectedLang} />
                </div>
            </div>
            <div className="social_block">
                <div>
                    <a href="/launchingSoon"><img src={appstore} alt="App Store" title="App Store" /></a>
                    <a href="/launchingSoon"><img src={googleplay} alt="Google Play" title="Google Play" /></a>
                </div>
                <div>
                    <a href="https://www.facebook.com/profile.php?id=61565215785552" target="blank"><FacebookIcon /></a>
                    <a href="https://x.com/ENKRIP_ID" target="blank"><TwitterIcon /></a>
                    <a href="https://www.linkedin.com/company/enkrip" target="blank"><LinkedIcon /></a>
                    <a href="https://www.instagram.com/enkrip_id/" target="blank"><InstagramIcon /></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
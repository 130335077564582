import { APP_NAME } from "features/App/constants";
import { getSocialAppLoginUrl } from "features/App/helpers";
import { useTranslation } from "react-i18next";
import NavigationLink from "Website/Layout/Components/NavigationLink";

export const AboutMenu = ({t}) => <NavigationLink to="/about" label={t("menu.about")} />
export const PrivacyMenu = ({t}) => <NavigationLink to="/privacy" label={t("menu.privacy")} />
export const TermsMenu = ({t}) => <NavigationLink to="/terms" label={t("menu.terms")} />
export const ContactUsMenu = ({t}) => <NavigationLink to="/contact" label={t("menu.contactUs")} />
export const EnkripWebMenu = ({t}) => <NavigationLink to={getSocialAppLoginUrl()} target="_blank" label={t("menu.ENKRIPWeb", {APP_NAME})} />

const menuType = {
    'AboutMenu': AboutMenu,
    'PrivacyMenu': PrivacyMenu,
    'TermsMenu': TermsMenu,
    'ContactUsMenu': ContactUsMenu,
    'EnkripWebMenu': EnkripWebMenu
}

const Menu = (props) => {
    const { t } = useTranslation();
    const displayMenu = props.displayMenu || ['AboutMenu', 'PrivacyMenu', 'ContactUsMenu', 'EnkripWebMenu'];
    return (
        <ul className={ `header_nav ${props.deviceType || ""}`} >
            {
                displayMenu.map((menuCompName) => {
                    const MenuComp = menuType[menuCompName];
                    return <li key={`menu-${menuCompName}`}>
                        <MenuComp t={t} />
                    </li>
                })
            }
        </ul>
    )
}

export default Menu;
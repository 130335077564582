const SelectLanguage = (props) => {

    const handleSelectLang = (e, lang) => {
        e.preventDefault();
        props.handleSetLanguage(lang);
    }

    return (
        <>
            {props.selectedLang === "en" && <a href="#" onClick={(e) => handleSelectLang(e, 'id')}>Bahasa Indonesia</a>}   
            {props.selectedLang === "id" && <a href="#" onClick={(e) => handleSelectLang(e, 'en')}>English</a>}
        </>
    )
}

export default SelectLanguage;
import { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { DEFAULT_LANGUAGE } from "Website/Language/constants";
import i18n from "features/Languages/i18n";
import { getSelectedLang } from "Website/Language/helpers";

const Layout = ({children}) => {
    const selectedLangStr = getSelectedLang(i18n.language);
    const [selectedLang, setSelectedLang] = useState(selectedLangStr || DEFAULT_LANGUAGE);

    const handleSetLanguage = (selectedLang) => {
        i18n.changeLanguage(selectedLang).then(() => {
            setSelectedLang(selectedLang);
        });
    }

    return (
        <>
            <Header handleSetLanguage={handleSetLanguage} selectedLang={selectedLang} />
                <div className="content_section">
                    {children}
                </div>
            <Footer handleSetLanguage={handleSetLanguage} selectedLang={selectedLang}/>
        </>
    )
}

export default Layout;